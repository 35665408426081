import { useEffect, useState } from 'react';
import { makeRangeArr, renderFormattedNum } from '../../utilities';
import Loading from '../../ui/Loading'
import './styles.scss'
import * as d3 from 'd3'

export default function Map(props) {
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        let colorScale
        let secondaryColorScale
        const responses = []

        const colorMap = {
            'cyan' : '194',
            'raspberry' : '328',
            'orange-slice': '39'
        }

        // Stop execution if no data
        if(props.choropleth && props.choropleth.length === 0) return

        // See if market coloring is selected
        const showMarkets = (props.choropleth) ? props.choropleth[0].hasOwnProperty('inMarket') : false

        // Set Colors
        let hue = colorMap[props.color]
        let hueSecondary = colorMap[props.secondaryColor ?? 0]
        let sat = '100%'

        // Define map size
        const width = 600,
        height = 430,
        strokeWidth = '0.5'

        // Clear SVG
        d3.selectAll(`#${props.id} .main`).remove()

        // The SVG
        const svg = d3.select(`#${props.id}`)
        .attr('viewBox', `0 0 ${width} ${height}`)
        .attr('width', '100%')
        .attr('height', '100%')
        
        // Main group
        const mainGroup = svg.append('g')
        .attr('class', 'main')
        .attr('transform', 'translate(0 0)')
        
        mainGroup.append('rect')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('x', 0)
        .attr('y', 0)
        .attr('fill', 'var(--blue-jeans)')

        mainGroup.append('g')

        svg.call(d3.zoom()
            .extent([[0, 0], [width, height]])
            .scaleExtent([1, 20])
            .on('zoom', zoomed))

            function zoomed({transform}) {
                mainGroup.attr('transform', transform);
            }

        // Projection
        const projection = d3.geoAlbersUsa()
        .translate([width / 2 , height / 2 - 30])
        .scale(width / 0.9)

        // Load geoJSON
        if(props.type === 'hrr') responses.push(d3.json('./data/maps/us-hrr.geojson'))
        if(props.type === 'county') responses.push(d3.json('./data/maps/us-county.geojson'))
        
        responses.push(d3.json('./data/maps/us-states.geojson'))
        
        Promise.all(responses)
        .then(render)

        // Render func
        function render(data) {
            const overlayTopo = data[0]
            const statesTopo = (data.length === 2) ? data[1] : data[0]

            // Title
            mainGroup.append('g')
            .append('text')
            .attr('fill', 'white')
            .attr('x', '50%')
            .attr('y', 30)
            .attr('text-anchor', 'middle')
            .style('font-weight', '800')
            .text(props.title ?? 'Map Title')

            // Legend
            if(props.market && !props.choropleth) {
                const itemPadding = 30
                const itemWidth = ((width - 200) - itemPadding) / props.market.legend.length
                const itemHeight = 30
                const fontSize = itemWidth * 0.045

                const legendNodes = mainGroup.append('g')
                .attr('id', 'legend')
                .attr('transform', `translate(${(width - itemWidth * props.market.legend.length) / 2} 360)`)
                .selectAll('svg')
                .data(props.market.legend)
                .enter()
                .append('svg')
                .attr('width', itemWidth)
                .attr('height', itemHeight)
                .attr('x', (d, idx) => itemWidth * idx)
                .attr('y', 0)

                // Legend color dots
                legendNodes
                .append('circle')
                .attr('cx', 20)
                .attr('cy', itemHeight / 2)
                .attr('r', itemHeight / 4)
                .attr('fill', d => `var(--${d.color})`)
                
                // Legend labels
                legendNodes
                .append('text')
                .text(d => d.label)
                .attr('fill', 'white')
                .style('font-size', fontSize + 'pt')
                .style('font-weight', '700')
                .attr('dominant-baseline', 'middle')
                .attr('y', itemHeight / 2 - fontSize - 1)
                .attr('dy', '1em')
                .attr('dx', 33)
            }

            // Choropleth style & legend

            if(props.choropleth) {
                let range = [
                    'white',
                    `hsl(${hue}, ${sat}, 95%)`,
                    `hsl(${hue}, ${sat}, 85%)`,
                    `hsl(${hue}, ${sat}, 75%)`,
                    `hsl(${hue}, ${sat}, 65%)`,
                    `hsl(${hue}, ${sat}, 55%)`
                ]

                const domain = makeRangeArr(props.choropleth, 'val', range.length)
                const makeLabels = () => {
                    const arr = []
                    const valueRange = domain
                    
                    valueRange.pop()
                    
                    valueRange.forEach((_, idx) => {
                        if(idx === valueRange.length - 1) {
                            arr.push(`${renderFormattedNum(valueRange[idx], props.currency, 0, false)}+`)
                            return
                        }

                        arr.push(`${renderFormattedNum(valueRange[idx], props.currency, 0, false)} - ${renderFormattedNum(valueRange[idx + 1] - 1, props.currency, 0, false)}`)
                    })
                    return arr
                }
                const legendColors = range.slice(1)
                const legendLabels = makeLabels()

                colorScale = d3.scaleThreshold()
                .domain(domain)
                .range(range)

                // Secondary color scale for showing markets
                range = [
                    'white',
                    `hsl(${hueSecondary}, ${sat}, 95%)`,
                    `hsl(${hueSecondary}, ${sat}, 85%)`,
                    `hsl(${hueSecondary}, ${sat}, 75%)`,
                    `hsl(${hueSecondary}, ${sat}, 65%)`,
                    `hsl(${hueSecondary}, ${sat}, 55%)`
                ]

                secondaryColorScale = d3.scaleThreshold()
                .domain(domain)
                .range(range)
                
                const itemWidth = (width) / legendColors.length 
                const itemHeight = 20

                function makeLegend(y, id, colors = legendColors) {
                    let legend = mainGroup.append('g')
                    .attr('id', id)
                    .style('transform', `translate(0, ${y}px) scale(0.9)`)
                    .style('transform-origin', 'center top')
                    .selectAll('svg')
                    .data(colors)
                    .enter()
                    .append('svg')
                    .attr('width', itemWidth)
                    .attr('height', itemHeight)
                    .attr('x', (_, idx) => itemWidth * idx)
                    .attr('y', 0)

                    legend.append('rect')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', '100%')
                    .attr('height', '100%')
                    .attr('fill', 'white')

                    legend.append('rect')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', '100%')
                    .attr('height', '100%')
                    .attr('fill', d => d)

                    legend.append('text')
                    .text((_, idx) => legendLabels[idx])
                    .attr('fill', 'var(--blue-jeans)')
                    .attr('text-anchor', 'middle')
                    .attr('dominant-baseline', 'central')
                    .attr('x', '50%')
                    .attr('y', '50%')
                    .style('font-size', '8pt')
                    .style('font-weight', '700')
                    .style('font-family', 'var(--font2)')

                    if(showMarkets) {
                        d3.select(`#${id}`)
                        .append('text')
                        .text(`${id} market`)
                        .attr('fill', 'white')
                        .style('text-transform', 'uppercase')
                        .style('font-size', '8pt')
                        .style('font-weight', '700')
                        .attr('y', -4)
                    }
                }

                makeLegend(360, 'primary')

                if(showMarkets) {
                    makeLegend(395, 'secondary', range.slice(1))
                }
            }

            // Base map
            mainGroup.append('g')
            .selectAll('path')
            .data(statesTopo.features)
            .enter()
            .append('path')
            .attr('fill', 'white')
            .attr('stroke-width', strokeWidth)
            .attr('stroke', 'var(--blue-jeans)')
            .attr('d', d3.geoPath().projection(projection))
            .attr('fill', 'white')

            // State paths stroked
            const states = mainGroup.append('g')
            .selectAll('path')
            .data(statesTopo.features)
            .enter()
            .append('path')
            .attr('d', d3.geoPath().projection(projection))
            .attr('fill', 'white')
            .attr('stroke-width', strokeWidth)
            .attr('stroke', 'var(--blue-jeans)')
            .attr('data-name', d => d.properties.name)
            .attr('data-val', d => props.choropleth?.filter(item => strLower(item.id) === strLower(d.properties.name))[0]?.val || 0)

            if(props.type === 'state') {
                states.attr('class', 'state')
                .attr('fill', d => {
                    if(props.choropleth) {
                        const total = props.choropleth.filter(item => strLower(item.id) === strLower(d.properties.name))[0]
                        return colorScale(total?.val || 0)
                    }
                })
                .on('mouseover', e => handleMouseOver(
                    (props.choropleth) ? `${e.target.dataset.name} (${renderFormattedNum(e.target.dataset.val, props.currency)})` : e.target.dataset.name
                ))
                .on('mouseout', handleMouseOut)
            }

            // County paths
            if(props.type === 'county' && props.type !== 'state') {
                mainGroup.append('g')
                .selectAll('path')
                .data(overlayTopo.features)
                .enter()
                .append('path')
                .attr('class', 'county')
                .attr('d', d3.geoPath().projection(projection))
                .attr('data-name', d => d.properties.NAME)
                .attr('data-num', d => d.properties.GEOID)
                .attr('data-val', d => props.choropleth?.filter(item => item.id === d.properties.GEOID)[0]?.val || 0)
                .attr('stroke', d => {
                    if(props.choropleth) {
                        const total = props.choropleth.filter(item => item.id === d.properties.GEOID)[0]
                        return total?.val > 0 ? `hsl(${hue}, ${sat}, 50%)` : 'var(--clouds)'
                    }
                    return 'var(--clouds)'
                })
                .attr('stroke-width', 0.5)
                .attr('fill', d => {
                    if(props.choropleth) {
                        const total = props.choropleth.filter(item => item.id === d.properties.GEOID)[0]
                        return colorScale(total?.val || 0)
                    }
                })
                .on('mouseover', e => handleMouseOver(
                    (props.choropleth) ? `${e.target.dataset.name} (${renderFormattedNum(e.target.dataset.val, props.currency)})` : `${e.target.dataset.name} (${e.target.dataset.num})` 
                ))
                .on('mouseout', handleMouseOut)
            }
            
            // HRR paths
            if(props.type === 'hrr' && props.type !== 'state') {
                mainGroup.append('g')
                .selectAll('path')
                .data(overlayTopo.features)
                .enter()
                .append('path')
                .attr('class', 'hrr')
                .attr('d', d3.geoPath().projection(projection))
                .attr('data-name', d => d.properties.hrrcity)
                .attr('data-num', d => d.properties.hrrnum)
                .attr('data-label', d => d.properties.HRR_lbl)
                .attr('data-state', d => d.properties.hrrcity.split(/\s/)[0].replace('-', ''))
                .attr('data-val', d => props.choropleth?.filter(item => item.id === d.properties.hrrnum)[0]?.val || 0)
                .attr('fill', d => {
                    if(!props.choropleth) {
                        if(props.market.primary.includes(d.properties.hrrnum)) return 'var(--raspberry-lt)'
                        return 'transparent'
                    }

                    if(props.choropleth) {
                        const total = props.choropleth.filter(item => item.id === d.properties.hrrnum)[0]

                        if(!showMarkets || total?.inMarket) return colorScale(total?.val || 0)
                        if(!total?.inMarket) return secondaryColorScale(total?.val || 0)
                    }
                })
                .attr('stroke-width', 0.3)
                .attr('stroke', d => {
                    if(!props.choropleth) {
                        if(props.market.primary.includes(d.properties.hrrnum)) return 'var(--raspberry)'
                        return 'var(--clouds)'
                    }

                    if(props.choropleth) {
                        const total = props.choropleth.filter(item => item.id === d.properties.hrrnum)[0]
                        
                        if(!showMarkets || total?.inMarket) return total?.val > 0 ? `hsl(${hue}, ${sat}, 50%)` : 'var(--clouds)'
                        if(!total?.inMarket) return total?.val > 0 ? `hsl(${hueSecondary}, ${sat}, 50%)` : 'var(--clouds)'
                    }
                })
                .on('mouseover', (e) => {
                    if(props.choropleth) {
                        handleMouseOver(`${e.target.dataset.label}, ${e.target.dataset.state} (${renderFormattedNum(e.target.dataset.val, props.currency)})`)
                        return
                    }

                    handleMouseOver(`${e.target.dataset.label}, ${e.target.dataset.state} (id: ${e.target.dataset.num})`)
                })
                .on('mouseout', handleMouseOut)
            }
            
            setLoading(false)
        }
    }, [props])

    return(
        <>
            {loading && <Loading /> }
            <svg id={props.id} className='map'></svg>
            <div className='map-tip' style={{opacity: 0}}></div>
        </>
    )
}

function handleMouseOver(text) {
    d3.select('.map-tip')
    .text(text)
    .style('opacity', '1')
    
    window.addEventListener('mousemove', e => {
        d3.select('.map-tip').style('left', e.clientX + 20 + 'px')
        .style('top', e.clientY - 10 + 'px')
    })
}

function handleMouseOut() {
    d3.select('.map-tip')
    .style('opacity', '0')
}

function strLower(val) {
    if(typeof val === 'string') return val.toLowerCase()
    return val
}
import { client, useConfig, useElementData } from '@sigmacomputing/plugin'
import { useEffect } from 'react'
import CompMap from '../components/Map'
  
export default function Choropleth() {

    useEffect(() => {
        client.config.configureEditorPanel([
            {
                name: "source",
                type: "element",
            },
            {
                name: "id",
                type: "column",
                source: "source",
                allowMultiple: false,
            },
            {
                name: "val",
                type: "column",
                source: "source",
                allowMultiple: false,
            },
            {
                name: "colorTheme",
                type: "dropdown",
                values: [
                    ' ',
                    'raspberry',
                    'cyan',
                    'orange-slice'
                ],
                source: "source"
            },
            {
                name: "markets",
                type: "column",
                source: "source",
                allowMultiple: false,
            },
            {
                name: "colorThemeSecondary",
                type: "dropdown",
                values: [
                    ' ',
                    'raspberry',
                    'cyan',
                    'orange-slice'
                ],
                source: "source"
            },
            {
                name: "title",
                type: "text"
            },
            {
                name: "mapType",
                type: "dropdown",
                values: [
                    ' ',
                    'hrr',
                    'county',
                    'state'
                ]
            },
            {
                name: "currency",
                type: "toggle"
            }
        ])
    }, [])
        
    const config = useConfig()
    const sigmaData = useElementData(config.source)
    const { title, mapType, colorTheme, currency, id, val, markets, colorThemeSecondary } = config
    const mapData = []

    if(Object.keys(sigmaData).length > 1 && id && val) {
        sigmaData[id].forEach((item, idx) => {
            const obj = {
                id: item,
                val: sigmaData[val][idx]
            }
            
            if(markets) obj['inMarket'] = sigmaData[markets][idx]

            mapData.push(obj)
        })
    }

    return (
        <CompMap 
        id="choropleth"
        title={title}
        choropleth={mapData}
        type={mapType}
        color={colorTheme}
        currency={currency}
        secondaryColor={colorThemeSecondary}
        />
    )
}

import { client, useConfig, useElementData } from '@sigmacomputing/plugin'
import { useEffect } from 'react'
import CompMap from '../components/Map'
  
export default function Markets() {

    useEffect(() => {
        client.config.configureEditorPanel([
            {
                name: "source",
                type: "element",
            },
            {
                name: "hrrCode",
                type: "column",
                source: "source",
                allowMultiple: false,
            },
            {
                name: "marketType",
                type: "column",
                source: "source",
                allowMultiple: false,
            },
            {
                name: "title",
                type: "text"
            }
        ])
    }, [])
        
    const config = useConfig()
    const sigmaData = useElementData(config.source)

    const { hrrCode, marketType, title } = config

    const marketData = { 
        primary: [],

        legend: [
            {
                color: 'raspberry',
                label: 'Primary Market'
            },
        
            {
                color: 'white',
                label: 'Secondary Market'
            }
        ]
    }

    if(Object.keys(sigmaData).length && hrrCode && marketType) {
        sigmaData[marketType].forEach((item, idx) => {
            if(item.toLowerCase() === 'primary') {
                marketData.primary.push(sigmaData[hrrCode][idx])
            }
        })
    }

    return (
        <CompMap 
        id="markets"
        title={title || 'ZERO Markets Map'}
        type='hrr'
        market={marketData}
        />
    )
}
